import { SHARED } from "@securitize/domain";
import {
  TaxFormType,
  TaxIdType,
  W8BENDocument,
  W8BENEDocument,
  W9Document,
} from "../../types/documents.interface";
import {
  W8BENEFormType,
  W8BENFormType,
  W9FormType,
} from "../../views/TaxForm/components/FormType";
import taxCenterGw from "../taxCenterGw";

// START: Helpers methods

const addExemptFATCAReportingCodes = (exemptFATCAReportingCodes: any) => {
  if (
    typeof exemptFATCAReportingCodes === "string" &&
    exemptFATCAReportingCodes !== ""
  ) {
    return [exemptFATCAReportingCodes];
  }
  return null;
};

const addExemptPayeeCodes = (exemptPayeeCodes: any) => {
  if (typeof exemptPayeeCodes === "string" && exemptPayeeCodes !== "") {
    return [Number(exemptPayeeCodes)];
  }
  return null;
};

const normalizeTaxPayerIdNumber = (taxPayerIdNumber: any) => {
  if (typeof taxPayerIdNumber === "string") {
    return taxPayerIdNumber.replace(/\D/g, "");
  }
  return null;
};

// END: Helpers methods

export const postW9TaxForm = async (
  formData: W9FormType,
  autoFilledTaxId: boolean,
) => {
  const payload: W9Document = {
    ...formData,
    formType: TaxFormType.W9,
    taxPayerIdType: formData.taxPayerIdType,
    taxPayerIdNumber: autoFilledTaxId
      ? null
      : normalizeTaxPayerIdNumber(formData.taxPayerIdNumber),
    formClassification: formData.formClassification
      .toUpperCase()
      .replaceAll("_", "-"),
    exemptPayeeCodes: addExemptPayeeCodes(formData.exemptPayeeCodes),
    exemptFATCAReportingCodes: addExemptFATCAReportingCodes(
      formData.exemptFATCAReportingCodes,
    ),
  };
  await taxCenterGw.post<any>("/tax-center/w9", payload);
};

export const postW8BENTaxForm = async (
  formData: W8BENFormType,
  autoFilledTaxId: boolean,
) => {
  const payload: W8BENDocument = {
    formType: TaxFormType.W8BEN,
    formInvestorName: formData.formInvestorName,
    birthDay: formData.birthDay,
    address1: formData.address1,
    address2: formData.address2,
    city: formData.city,
    region: formData.region,
    postalCode: formData.postalCode,
    country: formData.country,
    countryOfCitizenship: formData.countryOfCitizenship,
    mailingAddress1: formData.mailingAddress1,
    mailingAddress2: formData.mailingAddress2,
    mailingCity: formData.mailingCity,
    mailingRegion: formData.mailingRegion,
    mailingPostalCode: formData.mailingPostalCode,
    mailingCountry: formData.mailingCountry,
    taxCountry: formData.taxCountry || SHARED.CountryCodes.USA,
    taxPayerIdType: formData.USTaxPayerId ? TaxIdType.SSN : TaxIdType.FOREIGN,
    taxPayerIdNumber: autoFilledTaxId
      ? null
      : normalizeTaxPayerIdNumber(
          formData.USTaxPayerId || formData.foreignTaxPayerId,
        ),
    exemptPayeeCodes: addExemptPayeeCodes(formData.exemptPayeeCodes),
    exemptFATCAReportingCodes: addExemptFATCAReportingCodes(
      formData.exemptFATCAReportingCodes,
    ),
  };
  await taxCenterGw.post<any>("/tax-center/w8ben", payload);
};

export const postW8BENETaxForm = async (formData: W8BENEFormType) => {
  const payload: W8BENEDocument = {
    ...formData,
    formType: TaxFormType.W8BENE,
  };
  await taxCenterGw.post<any>("/tax-center/w8ben-e", payload);
};

export const getW8BENETaxFormExample = async () => {
  const { data } = await taxCenterGw.get<{ url: string }>(
    "/tax-center/w8ben-e",
  );
  return data;
};

export const getW8BENEPresignedUrls = async (contentType: string) => {
  const { data } = await taxCenterGw.get<{
    url: string;
    fields: Record<string, string>;
    fileKey: string;
  }>(`/tax-center/w8ben-e/upload?contentType=${contentType}`);
  return data;
};
