const config = {
  GW_BASE_URL: process.env.REACT_APP_GW_BASE_URL || 'null-ref',
  I18N_SYSTEM: process.env.REACT_APP_I18N_SYSTEM || '',
  AUTH_API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '',
  LOGIN: {
    AUTOMATION_CAPTCHA_KEY: process.env.REACT_APP_LOGIN_AUTOMATION_CAPTCHA_KEY!,
    USERNAME: process.env.REACT_APP_LOGIN_USERNAME!,
    PASSWORD: process.env.REACT_APP_LOGIN_PASSWORD!,
  },
} as const;

export default config;
